import React, { useEffect, useState } from 'react'
import { Header } from '../component/header/header'
import { Footer } from '../component/footer/footer'

import gearshift from "../assets/images/gearshift.png";

import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { featureData, optionBody, optionCondition, optionMake, optionMaxYear, optionMinYear, optionModel, optionTransmission, optionYear } from '../utils/optionData';
import api from '../api';
import { LoaderComp } from '../component/loader/loader';

export const Stock = () => {
    const navigate=useNavigate()

    const [data, setData] = useState([]);
    const [page, setPage]=useState(1)
    const [limit, setLimit]=useState(10)
    const [loader, setLoader] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [noOfVehicle, setnoOfVehicle]=useState(0)
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [formDataVal, setFormDataVal] = useState({});

    const getVehicleData = async()=>{
      try {
        let payload = {
          page:page
        }
        setLoader(true)
        const postData = await api.getVehicle(payload)
        console.log(postData);
        if(page == 1) {
            setData(postData.vehicle)
        }else{
            setData([...data, ...postData.vehicle])
        }
        setLimit(postData.limit)
        setnoOfVehicle(postData.count)
        setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    }
    const getMakers = async()=>{
        try {
        
          setLoader(true)
          const postData = await api.getAllMake()
        //   console.log(postData);
          if(postData.success){
            let data = postData.make.map((d)=> (
              {value:d.name,label:d.name,_id:d._id}
            ));
            // console.log(data);
            setMakers(data)
          }else {
          alert.show("Something went wrong.")
          }
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const getModels = async()=>{
        try {
          setLoader(true)
          const postData = await api.getAllModels()
        //   console.log(postData);
          let data = postData.model.map((d)=> (
            {value:d.model,label:d.model,_id:d._id}
          ));
          setModels(data)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }
    useEffect(() => {
        if(Object.keys(window.location.search).length == 0){
            getVehicleData()
        } 
    
    }, [page,isRefresh])

    useEffect(() => {
        getMakers()
        getModels()
      }, [])

    function numDifferentiation(value) {
        const val = Math.abs(value)
        if (val >= 10000000) return `${Number.parseFloat((value / 10000000).toFixed(2))} Cr`
        if (val >= 100000) return `${Number.parseFloat((value / 100000).toFixed(2))} Lac`
        return value;
      }
    
      const queryStringToObject = url =>
        Object.fromEntries([...new URLSearchParams(url.split('?')[1])]);

      const objectToQueryString = (dataObject) => {
        let queryString = Object.keys(dataObject).map((key) => { 
          if (Array.isArray(dataObject[key])) { 
            return dataObject[key].map((item, index) => { 
              return encodeURIComponent(key) + '[' + index + ']=' + encodeURIComponent(item); }).join('&');
          } else { 
            return encodeURIComponent(key) + '=' + encodeURIComponent(dataObject[key])
          }
        }).join('&');
        return queryString
      }

    const handleData = (e,type) => {
        // console.log(e);
        if(type){
          setFormDataVal({...formDataVal,[type]:e})
        }else{
          setFormDataVal({...formDataVal,[e.target.name]:e.target.value})
        }
      } 

    const searchFilter = async(obj) => {
        try {
            setLoader(true)
            let payload = obj;
            if(payload?.make){
                let fil = makers.find(d=>d.value == payload?.make)
                payload.make = fil._id
            }
            if(payload?.model){
                let fil = models.find(d=>d.value == payload?.model)
                payload.model = fil._id || payload?.model
            }
            payload.country= "pakistan"
            console.log(payload);
            
            const postData = await api.getfileteredVehicle(obj)
            // console.log(postData);
            if(postData?.success) {
                setData(postData.vehicle)
                setnoOfVehicle(postData.vehicle.length)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formDataVal);
        // console.log(Object.keys(formDataVal));
        if(Object.keys(formDataVal).length >0){
            let queryString = objectToQueryString(formDataVal)
            navigate(`/stock/pakistan/?${queryString}`)
        }
    }

    const resetFilter = () => {
        setFormDataVal({})
        navigate('/stock/pakistan/')
        page == 1 ? setIsRefresh(!isRefresh): setPage(1)
    }


    useEffect(() => {
        if(makers.length > 0 && models.length > 0 ){
            if(Object.keys(window.location.search).length > 0){
                let obj = queryStringToObject(window.location.search)
                // console.log(obj);
                searchFilter(obj)
            } 
        }
    }, [makers,models,window.location.search])

// console.log(formDataVal);



  return (
    <div className='stockPage'>
        <Header />
        <div className="container">
            <div className="stockMain">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="filterSidebar">
                            {/* <div className="closeBtn">
                                <i className="fa-regular fa-circle-xmark"></i>                          
                            </div> */}
                            <div className="r1">
                                <div className="title">Search Options</div>
                                <div className="ico"><i class="fa-solid fa-car-rear"></i></div>
                            </div>
                            <form className="searchM">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="condition"
                                        options={optionCondition}
                                        placeholder={"Condition..."}
                                        onChange={(e)=>handleData(e.value,"condition")}

                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="bodyType"
                                        options={optionBody}
                                        placeholder={"Body..."}
                                        onChange={(e)=>handleData(e.value,"bodyType")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        // defaultValue={optionMake[0]}
                                        // isLoading={isLoading}
                                        // isClearable={isClearable}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="make"
                                        options={makers}
                                        placeholder={"Make..."}
                                        onChange={(e)=>handleData(e.value,"make")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="model"
                                        options={models}
                                        placeholder={"Model..."}
                                        onChange={(e)=>handleData(e.value,"model")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="transmission"
                                        options={optionTransmission}
                                        placeholder={"Transmission..."}
                                        onChange={(e)=>handleData(e.value,"transmission")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="minYear"
                                        options={optionYear}
                                        placeholder={"Min Year..."}
                                        onChange={(e)=>handleData(e.value,"minYear")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="MaxYear"
                                        options={optionYear}
                                        placeholder={"Max Year..."}
                                        onChange={(e)=>handleData(e.value,"maxYear")}
                                    />
                                </div>
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='mileage' value={formDataVal?.mileage} className='inputStyle' type="text" placeholder='Max Mileage' />
                                </div>
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='maxPrice' value={formDataVal?.maxPrice} className='inputStyle' type="text" placeholder='Max Price' />
                                </div>
                                <div>
                                    <button className="themeBtn w-100" onClick={handleSubmit}
                                     disabled={Object.keys(formDataVal).length > 0 ?false:true} >
                                    SEARCH</button>
                                </div>
                            </form>
                            <div className="reset" onClick={resetFilter}>Reset All</div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="stckSec">
                            <div className="head">
                                <div className="title">Vehicles For Sale</div>
                            </div>
                            <div className="r2">
                                <div className="title">Vehicle ({noOfVehicle})</div>
                            </div>
                            <div className="productCardMain">
                                <div className="row">
                                {data.map((item,key)=>(
                                    <div className="col-lg-4 col-sm-6 mb-3" key={key}>
                                        <div className="productCard" onClick={()=>navigate(`/stock/${item?.stockId}`)}>
                                            <div className="imgDiv">
                                                <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item?.images[0]}`} alt="" />
                                            </div>
                                            <div className="titleRow">
                                                <div className="title">{item?.title}</div>
                                                {item?.price == 0 ?
                                                <div className="price">Call for Price</div>
                                                :
                                                <div className="price">Rs {numDifferentiation(item?.price)}</div>
                                                }
                                            </div>
                                            <div className="statsRow">
                                                <div className="val">
                                                    {item?.transmission}
                                                    <img className='ml-1' src={gearshift} alt="" />
                                                </div>
                                                <div className="val">
                                                    {item?.mileage} mi
                                                    <i className="fa-solid fa-road ml-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                                {((limit * page) < noOfVehicle) &&
                                <div className="text-center mt-4">
                                    <button className="themeBtn" onClick={()=>setPage(page + 1)}>Show More</button>
                                </div>
                                }
                                {data.length == 0 && 
                                 <h2>No Data Available</h2>
                                 }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {loader && 
        <LoaderComp />}
    </div>
  )
}
