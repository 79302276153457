import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/css/animate.css';
import './admin/vehicle/vehicle.css';
import {
  BrowserRouter as Router, Routes, Route, Link, useLocation,
  Navigate,
  useNavigate
} from "react-router-dom";
import { Home } from './pages/home';
import { ContactPage } from './pages/contactPage';
import { Footer } from './component/footer/footer';
import { AboutPage } from './pages/aboutPage';
import { Stock } from './pages/stock';
import { DetailPage } from './pages/detailPage';
import { Login } from './pages/login';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './admin/sidebar/sidebar';
import { NavbarAdmin } from './admin/navbar/navbar';
import Vehicles from './admin/vehicle/vehicle';
import Maker from './admin/make/make';
import Models from './admin/model/model';
import { AddVehicle } from './admin/vehicle/addVehicle';
import ReviewAdmin from './admin/review/review';
import api from './api';
import adminAction from "./redux/admin/action";
import axios from 'axios';
import Team from './admin/team/team';
import { ContactPageUAE } from './pages/contactUAE';
import { EditVehicle } from './admin/vehicle/editVehicle';
import Reciept from './admin/reciept/reciept';
import { StockUAE } from './pages/stockUAE';

function App() {
  const location = useLocation()
  const adminStore = useSelector((state) => state.adminReducer);
  const dispatch = useDispatch();
  const {setAdmin} = adminAction;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top:0,behavior:'instant'});
  }, [location.pathname])

  const checkSession = async(token)=> {
    try {
      let payload = {
        token:token
      }
      const res = await api.checkSession(payload) 
      // console.log(res);
      if(res?.token) {
        axios.defaults.headers.common["Authorization"] = res?.token;
        // let payload = adminStore.admin
        // payload.token = res?.token
        // dispatch(setAdmin(payload));

      }else{
        logout()
      }
    } catch (error) {
      console.log(error);
    }
  }
  const logout = () => {
    dispatch(setAdmin({}))
    localStorage.clear()
    navigate("/")
  }

  useEffect(() => {
    if(adminStore?.admin?.token) {
      checkSession(adminStore?.admin?.token)
    }
  }, [])
  
  // console.log(adminStore.admin);

  return (
    <div className="App">

      {adminStore?.admin?.admin?._id ?
          <div id="wrapper">
              <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <NavbarAdmin />
                    <Routes>
                      <Route exact={true} path="/admin/vehicles" element={<Vehicles />} />
                      <Route exact={true} path="/admin/make" element={<Maker />} />
                      <Route exact={true} path="/admin/model" element={<Models />} />
                      <Route exact={true} path="/admin/add-vehicle" element={<AddVehicle />} />
                      <Route exact={true} path="/admin/edit-vehicle/:stockId" element={<EditVehicle />} />
                      <Route exact={true} path="/admin/review" element={<ReviewAdmin />} />
                      <Route exact={true} path="/admin/team" element={<Team />} />
                      <Route exact={true} path="/admin/reciept" element={<Reciept />} />
                      <Route
                          path="*"
                          element={<Navigate to="/admin/make" replace />}
                      />
                    </Routes>
                    
                </div>
            </div>
          </div>
          :
          <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/contact-uae" element={<ContactPageUAE />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/stock/pakistan" element={<Stock />} />
            <Route path="/stock/uae" element={<StockUAE />} />
            <Route path="/stock/:id" element={<DetailPage />} />
            <Route path="/pakauto-admin-login" element={<Login />} />
          </Routes>
          <Footer /> 
          </>
          }
    </div>
  );
}

export default App;
