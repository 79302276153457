import React, { useEffect, useState } from 'react'
import { optionBody, optionCondition, optionCountry, optionDrive, optionFuelType, optionLocation, optionMake, optionRegistered, optionTransmission, optionYear } from '../../utils/optionData'
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import api from '../../api';
import { useAlert } from 'react-alert';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import { LoaderComp } from '../../component/loader/loader';

var Editor = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
    
}

export const EditVehicle = () => {
    const alert = useAlert()
    const {stockId} = useParams()
    const navigate = useNavigate()
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isWaterMark, setIsWaterMark] = useState(false);
    const [upImages, setUpImages] = useState([]);
    const [userinfo, setUserInfo] = useState({
        comfort: [],
        entertainment: [],
        safety: [],
        seats: [],
    });
    const [formDataVal, setFormDataVal] = useState({});
    const [phoneNum, setPhoneNum]= useState("");
    const [Whatsapp, setWhatsapp]= useState("");
    const [initalRender, setInitalRender]= useState(false);
    const [noData, setNoData] = useState(false);
    const [editData, setEditData] = useState({});

    const handleChangeCheck = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { comfort,entertainment,safety,seats } = userinfo;

        // console.log(`name is ${e.target.name} ${value} is ${checked}`);

        // Case 1 : The user checks the box
        if (checked) {
            if(e.target.name == "comfort"){
                setUserInfo({
                    comfort: [...comfort, value], 
                    entertainment:[...entertainment], 
                    safety:[...safety], 
                    seats:[...seats]
                });
            }
            else if(e.target.name == "entertainment"){
                setUserInfo({
                    entertainment: [...entertainment, value],
                    comfort:[...comfort],
                    safety:[...safety],
                    seats:[...seats]
                });
            }
            else if(e.target.name == "safety"){
                setUserInfo({
                    safety: [...safety, value],
                    comfort:[...comfort],
                    entertainment:[...entertainment],
                    seats:[...seats]
                });
            }
            else if(e.target.name == "seats"){
                setUserInfo({seats: [...seats, value], 
                    comfort:[...comfort],
                    entertainment:[...entertainment],
                    safety:[...safety]
                });
            }
        }
        // Case 2  : The user unchecks the box
        else {
            if(e.target.name == "comfort"){
                setUserInfo({
                    comfort: comfort.filter((e) => e !== value),
                    entertainment:[...entertainment], 
                    safety:[...safety], 
                    seats:[...seats]
                });
            }
            else if(e.target.name == "entertainment"){
                setUserInfo({
                    entertainment: entertainment.filter((e) => e !== value),
                    comfort:[...comfort],
                    safety:[...safety],
                    seats:[...seats]
                });
            }
            else if(e.target.name == "safety"){
                setUserInfo({
                    safety: safety.filter((e) => e !== value),
                    comfort:[...comfort],
                    entertainment:[...entertainment],
                    seats:[...seats]
                });
            }
            else if(e.target.name == "seats"){
                setUserInfo({
                    seats: seats.filter((e) => e !== value),
                    comfort:[...comfort],
                    entertainment:[...entertainment],
                    safety:[...safety]
                });
            }
        }
    };

    // console.log(userinfo);
    
    const handleChangeDesc = (e) => {
        // console.log(e);
        setFormDataVal({...formDataVal,"description":e})
    }
    const handleImages = async(e) => {
        var tempFiles=[]
        let files = e.target.files || []
        // console.log(files);
        for (let i = 0; i < files.length; i++) {
            if(files[i].size > 300000){
                alert.show("You can upload each file max 300kb file")
              }else{
                  tempFiles.push(files[i])
              }
        }
        // console.log(tempFiles);
        const formData = new FormData();

        tempFiles.forEach((images, index) => {
            formData.append(`images`, images);
          });
        const res = await api.uploadImages(formData)
        // console.log("images res");
        // console.log(res);

        if(res?.success){
            let tempEdit = [...upImages]
            if(res?.images.length > 0){
                    for (let i = 0; i < res?.images.length; i++) {
                        tempEdit.push(res?.images[i])
                    }
                setUpImages(tempEdit)
            }
            // else{
                
            //     setUpImages(tempFiles)
            // }
        }
        
    }
    console.log(upImages);

    const removeImg = (id) => {
        let arr1 = [...upImages]
        arr1.splice(id, 1);
        console.log(arr1);
        setUpImages(arr1)
    }

    const getMakers = async()=>{
        try {
          setLoader(true)
          const postData = await api.getAllMake()
        //   console.log(postData);
          if(postData.success){
            let data = postData.make.map((d)=> (
              {value:d._id,label:d.name}
            ));
            // console.log(data);
            setMakers(data)
          }else {
          alert.show("Something went wrong.")
          }
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const getModels = async()=>{
        try {
          setLoader(true)
          const postData = await api.getAllModels()
        //   console.log(postData);
          let data = postData.model.map((d)=> (
            {value:d._id,label:d.model}
          ));
          setModels(data)
          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
        }
      }

      const handleData = (e,type) => {
        // console.log(e);
        if(type){
          setFormDataVal({...formDataVal,[type]:e})
        }else{
          setFormDataVal({...formDataVal,[e.target.name]:e.target.value})
        }
      } 

      useEffect(() => {
        getMakers()
        getModels()
      }, [])
   
    //   console.log(userinfo);
    //   console.log(stockId);
      
    //   console.log(upImages);
    //   console.log(typeof upImages);
    const checkValidation = () => {
        if(!formDataVal?.title) return "Please insert title";
        if(!phoneNum) return "Please insert model phone";
        if(!Whatsapp) return "Please insert model whatsapp";
        if(!formDataVal?.location) return "Please select location";
        if(!formDataVal?.country) return "Please insert country";
        if(!formDataVal?.stockId) return "Please insert stock ID";
        if(!formDataVal?.mileage) return "Please insert mileage";
        if(!formDataVal?.engine) return "Please select engine cc";
        if(!formDataVal?.year) return "Please select year";
        if(!formDataVal?.make) return "Please select maker";
        if(!formDataVal?.model) return "Please select model";
        if(!formDataVal?.condition) return "Please select condition";
        if(!formDataVal?.bodyType) return "Please select body type";
        if(!formDataVal?.transmission) return "Please insert transmission";
        if(!formDataVal?.drive) return "Please insert drive type";
        if(!formDataVal?.registered) return "Please select registered type";
        if(!formDataVal?.fuelType) return "Please select fuel type";
        if(!formDataVal?.grade) return "Please insert grade";
        if(!formDataVal?.package) return "Please insert package";
        if(!formDataVal?.interior) return "Please insert interior";
        if(!formDataVal?.exterior) return "Please insert exterior";
        if(userinfo?.comfort.length == 0) return "Please select minimum 1 value from comfort";
        if(userinfo?.entertainment.length == 0) return "Please select minimum 1 value from entertainment";
        if(userinfo?.safety.length == 0) return "Please select minimum 1 value from safety";
        if(userinfo?.seats.length == 0) return "Please select minimum 1 value from seats";
        if(!formDataVal?.description) return "Please add description";
        if(upImages.length == 0) return "Please select images";
        return false
      }

      const handleSubmit = async()=> {
        try {
            
            let checks = checkValidation()
            if(checks){
                // console.log(checks);
                
                return alert.show(checks)
            }  
            let payload = {
                "_id": editData?._id,
                "seller": formDataVal?.seller,
                "seller": formDataVal?.seller,
                "title": formDataVal?.title,
                "price": formDataVal?.price || 0 ,
                "phone": phoneNum,
                "whatsapp": Whatsapp,
                "location": formDataVal?.location,
                "country": formDataVal?.country,
                "stockId": formDataVal?.stockId,
                "mileage": formDataVal?.mileage,
                "engine": formDataVal?.engine,
                "year": formDataVal?.year,
                "make": formDataVal?.make,
                "model": formDataVal?.model,
                "condition": formDataVal?.condition,
                "bodyType": formDataVal?.bodyType,
                "transmission": formDataVal?.transmission,
                "drive": formDataVal?.drive,
                "registered": formDataVal?.registered,
                "fuelType": formDataVal?.fuelType,
                "grade": formDataVal?.grade,
                "package": formDataVal?.package,
                "interior": formDataVal?.interior,
                "exterior": formDataVal?.exterior,
                "comfort": userinfo?.comfort.toString(),
                "entertainment": userinfo?.entertainment.toString(),
                "safety": userinfo?.safety.toString(),
                "seats": userinfo?.seats.toString(),
                "manufacturer": formDataVal?.manufacturer,
                "description": formDataVal?.description,
                "featured": isFeatured,
                "waterMark": isWaterMark,
                "images": upImages,
            }
            //  console.log(payload);
             
            setLoader(true)
            // console.log("submit");
            // console.log(formDataVal);
            // console.log(userinfo);
            // console.log(upImages);
            const res = await api.editVehicle(payload)
            // console.log(res);
            if(res?.success) {
                alert.show("Vehicle updated")
             
                setUserInfo({
                    comfort: [],
                    entertainment: [],
                    safety: [],
                    seats: [],
                })
                setFormDataVal({})
                setUpImages([])
                setLoader(false)
                navigate("/admin/vehicles")
            }else{
                console.log(res);
                setLoader(false)
                alert.show("Something went wrong")
            }
            
        } catch (error) {
            console.log(error);
            console.log(error.message);
            
            alert.show(error.message)
            setLoader(false)
        }
        
      }

      function selectAll() {
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const { comfort,entertainment,safety,seats } = userinfo;
        var comfortTemp = []
        var entertainmentTemp = []
        var safetyTemp = []
        var seatsTemp = []
        for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                if(checkboxes[i].name == "comfort"){                    
                    comfortTemp.push(checkboxes[i].value)
                }
                if(checkboxes[i].name == "entertainment"){                    
                    entertainmentTemp.push(checkboxes[i].value)
                }
                if(checkboxes[i].name == "safety"){                    
                    safetyTemp.push(checkboxes[i].value)
                }
                if(checkboxes[i].name == "seats"){                    
                    seatsTemp.push(checkboxes[i].value)
                }
        }
        setUserInfo({
            comfort:[...comfortTemp],
            entertainment: [...entertainmentTemp],
            safety:[...safetyTemp],
            seats:[...seatsTemp]
        });
        setIsFeatured(true)
        setIsWaterMark(true)
    }

    
    
    useEffect(() => {
        if(stockId){
            getVehicleData()
        }
      }, [])

      const getVehicleData = async()=>{
        try {
          
          setLoader(true)
          const postData = await api.getVehicleByStockId(stockId)
        //   console.log(postData);
          //   setData(postData.vehicle)
          if(!postData.vehicle){
              setNoData(true)
            }
            setEditData(postData?.vehicle)
        //   setFormDataVal
        let payload1 = {
            seller:postData?.vehicle?.seller,
            title:postData?.vehicle?.title,
            price:postData?.vehicle?.price,
            location:postData?.vehicle?.location,
            country:postData?.vehicle?.country,
            stockId:postData?.vehicle?.stockId,
            mileage:postData?.vehicle?.mileage,
            engine:postData?.vehicle?.engine,
            year:postData?.vehicle?.year,
            make:postData?.vehicle?.make?._id,
            model:postData?.vehicle?.model?._id,
            condition:postData?.vehicle?.condition,
            bodyType:postData?.vehicle?.bodyType,
            transmission:postData?.vehicle?.transmission,
            drive:postData?.vehicle?.drive,
            registered:postData?.vehicle?.registered,
            fuelType:postData?.vehicle?.fuelType,
            grade:postData?.vehicle?.grade,
            package:postData?.vehicle?.package,
            interior:postData?.vehicle?.interior,
            exterior:postData?.vehicle?.exterior,
            manufacturer:postData?.vehicle?.manufacturer,
            description:postData?.vehicle?.description,
        }
        setFormDataVal(payload1)
        //   setFormDataVal

        // setPhone
        setPhoneNum(postData?.vehicle?.phone)
        setWhatsapp(postData?.vehicle?.whatsapp)
        // setPhone
        
        //setuserinfo
        setUserInfo({
            comfort: postData?.vehicle?.comfort[0].split(','), 
            entertainment:postData?.vehicle?.entertainment[0].split(','), 
            safety:postData?.vehicle?.safety[0].split(','), 
            seats:postData?.vehicle?.seats[0].split(',')
        });
        //setuserinfo

        setIsFeatured(postData?.vehicle?.featured)
        setIsWaterMark(postData?.vehicle?.waterMark)

        setUpImages(postData?.vehicle?.images)

          setLoader(false)
        } catch (error) {
          console.log(error);
          setLoader(false)
          setNoData(true)
        }
      }

    //   console.log(formDataVal);
    //   console.log(userinfo);
    //   console.log(upImages);
      
      
  return (
    <div className="container-fluid">
    {/* Content Row */}
     <div className="row">
     {/* Area Chart */}
     {noData ?
      <div className="col">
        <div className="row mx-0 drivers-row">
            <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Invalid Id</h4>
            </div>
        </div>
      </div>
      :  
     <div className="col">
        <div className="row mx-0 drivers-row">
            <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">Add Vehicles</h4>
            </div>
        </div>
        <div className="verhicleForm addVForm">
            <div className="form">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='seller' value={formDataVal?.seller} className='form-control border-0' type="text" placeholder='Seller name...' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='title' value={formDataVal?.title} className='form-control border-0' type="text" placeholder='Title...' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='price' value={formDataVal?.price} className='form-control border-0' type="number" placeholder='Price...' />
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='phone' value={formDataVal?.phone} className='form-control border-0' type="number" placeholder='Phone...' />
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div className="form-group phone">
                                <PhoneInput
                                    className="form-control"
                                    international
                                    countryCallingCodeEditable={false}
                                    value={phoneNum}
                                    onChange={setPhoneNum}
                                    placeholder="Enter Phone Number"
                                />                            
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group phone">
                                <PhoneInput
                                    className="form-control"
                                    international
                                    countryCallingCodeEditable={false}
                                    value={Whatsapp}
                                    onChange={setWhatsapp}
                                    placeholder="Enter Whatsapp Number"
                                />                            
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='whatsapp' value={formDataVal?.whatsapp} className='form-control border-0' type="number" placeholder='Whatsapp...' />
                                </div>
                            </div> */}
                            <div className="col-12">
                                {/* <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='location' value={formDataVal?.location} className='form-control border-0' type="text" placeholder='Location...' />
                                </div> */}
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="location"
                                        options={optionLocation}
                                        value={optionLocation.find(d=>d.value == formDataVal?.location)}

                                        placeholder={"Location..."}
                                        onChange={(e)=>handleData(e.value,"location")}
                                    />
                                </div>
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="country"
                                        options={optionCountry}
                                        value={optionCountry.find(d=>d.value == formDataVal?.country)}

                                        placeholder={"Country..."}
                                        onChange={(e)=>handleData(e.value,"country")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='stockId' value={formDataVal?.stockId} className='form-control border-0' type="number" placeholder='Stock ID...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='mileage' value={formDataVal?.mileage} className='form-control border-0' type="text" placeholder='Mileage...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='engine' value={formDataVal?.engine} className='form-control border-0' type="number" placeholder='Engine cc...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="MinYear"
                                        options={optionYear}
                                        value={optionYear.find(d=>d.value == formDataVal?.year)}

                                        placeholder={"Year..."}
                                        onChange={(e)=>handleData(e.value,"year")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        value={makers.find(d=>d.value == formDataVal?.make)}
                                        // isLoading={isLoading}
                                        // isClearable={isClearable}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Make"
                                        options={makers}
                                        // value={formDataVal?.make}
                                        placeholder={"Select Maker"}
                                        onChange={(e)=>handleData(e.value,"make")}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        value={models.find(d=>d.value == formDataVal?.model)}
                                        // defaultValue={optionMake[0]}
                                        // isLoading={isLoading}
                                        // isClearable={isClearable}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Model"
                                        options={models}
                                        placeholder={"Select Model"}
                                        onChange={(e)=>handleData(e.value,"model")}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Condition"
                                        options={optionCondition}
                                        value={optionCondition.find(d=>d.value == formDataVal?.condition)}
                                        placeholder={"Condition..."}
                                        onChange={(e)=>handleData(e.value,"condition")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Body"
                                        options={optionBody}
                                        value={optionBody.find(d=>d.value == formDataVal?.bodyType)}
                                        placeholder={"Body..."}
                                        onChange={(e)=>handleData(e.value,"bodyType")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Transmission"
                                        options={optionTransmission}
                                        value={optionTransmission.find(d=>d.value == formDataVal?.transmission)}
                                        placeholder={"Transmission..."}
                                        onChange={(e)=>handleData(e.value,"transmission")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Drive"
                                        options={optionDrive}
                                        value={optionDrive.find(d=>d.value == formDataVal?.drive)}
                                        placeholder={"Drive..."}
                                        onChange={(e)=>handleData(e.value,"drive")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="Fuel Type"
                                        options={optionFuelType}
                                        value={optionFuelType.find(d=>d.value == formDataVal?.fuelType)}
                                        placeholder={"Fuel Type..."}
                                        onChange={(e)=>handleData(e.value,"fuelType")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select
                                        className="basic-single input"
                                        classNamePrefix="select"
                                        isRtl={false}
                                        isSearchable={true}
                                        name="registered"
                                        options={optionRegistered}
                                        value={optionRegistered.find(d=>d.value == formDataVal?.registered)}
                                        placeholder={"Registered..."}
                                        onChange={(e)=>handleData(e.value,"registered")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='grade' value={formDataVal?.grade} className='form-control border-0' type="text" placeholder='Grade ...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='package' value={formDataVal?.package} className='form-control border-0' type="text" placeholder='Package  ...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='interior' value={formDataVal?.interior} className='form-control border-0' type="text" placeholder='Interior Color ...' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input onChange={(e)=>handleData(e)} name='exterior' value={formDataVal?.exterior} className='form-control border-0' type="text" placeholder='Exterior Color...' />
                                </div>
                            </div>

                        </div>
                        <div className="featureMain">
                            <div className="selectAll" onClick={selectAll}>Select All</div>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <h4 className='mb-3'>Features</h4>
                                </div>
                                <div className="col-md-6">
                                    <div className="h6">Comfort</div>
                                    <ul>
                                        <li>
                                            <input  onChange={handleChangeCheck} checked={userinfo.comfort.includes("A/C: Front")} className='form-check-input' type="checkbox" name="comfort" value="A/C: Front" id="" />
                                            <label className='form-check-label'>A/C: Front</label>
                                        </li>
                                        <li>
                                            <input  onChange={handleChangeCheck} checked={userinfo.comfort.includes("A/C: Back")} className='form-check-input' type="checkbox" name="comfort" value="A/C: Back" id="" />
                                            <label className='form-check-label'>A/C: Back</label>
                                        </li>
                                        <li>
                                            <input  onChange={handleChangeCheck} checked={userinfo.comfort.includes("backup camera")} className='form-check-input' type="checkbox" name="comfort" value="backup camera" id="" />
                                            <label className='form-check-label'>Backup Camera</label>
                                        </li>
                                        <li>
                                            <input  onChange={handleChangeCheck} checked={userinfo.comfort.includes("cruise control")} className='form-check-input' type="checkbox" name="comfort" value="cruise control" id="" />
                                            <label className='form-check-label'>Cruise Control</label>
                                        </li>
                                        <li>
                                            <input  onChange={handleChangeCheck} checked={userinfo.comfort.includes("navigation")} className='form-check-input' type="checkbox" name="comfort" value="navigation" id="" />
                                            <label className='form-check-label'>Navigation</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="h6">Entertainment</div>
                                    <ul>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.entertainment.includes("MP3 player")} className='form-check-input' type="checkbox" name="entertainment" value="MP3 player" id="" />
                                            <label className='form-check-label'>MP3 Player</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.entertainment.includes("LED panel")} className='form-check-input' type="checkbox" name="entertainment" value="LED panel" id="" />
                                            <label className='form-check-label'>LED Panel</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.entertainment.includes("sun roof")} className='form-check-input' type="checkbox" name="entertainment" value="sun roof" id="" />
                                            <label className='form-check-label'>Sun Roof</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.entertainment.includes("AM/FM Stereo")} className='form-check-input' type="checkbox" name="entertainment" value="AM/FM Stereo" id="" />
                                            <label className='form-check-label'>AM/FM Stereo</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="h6">Safety</div>
                                    <ul>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.safety.includes("airbag: driver")} className='form-check-input' type="checkbox" name="safety" value="airbag: driver" id="" />
                                            <label className='form-check-label'>Airbag: Driver</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.safety.includes("airbag: passenger")} className='form-check-input' type="checkbox" name="safety" value="airbag: passenger" id="" />
                                            <label className='form-check-label'>Airbag: Passenger</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.safety.includes("security system")} className='form-check-input' type="checkbox" name="safety" value="security system" id="" />
                                            <label className='form-check-label'>Security System</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.safety.includes("antilock brakes")} className='form-check-input' type="checkbox" name="safety" value="antilock brakes" id="" />
                                            <label className='form-check-label'>Antilock Brakes</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <div className="h6">Seats</div>
                                    <ul>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.seats.includes("heated seats")} className='form-check-input' type="checkbox" name="seats" value="heated seats" id="" />
                                            <label className='form-check-label'>Heated Seats</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.seats.includes("power seats")} className='form-check-input' type="checkbox" name="seats" value="power seats" id="" />
                                            <label className='form-check-label'>Power Seats</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.seats.includes("bucket seats")} className='form-check-input' type="checkbox" name="seats" value="bucket seats" id="" />
                                            <label className='form-check-label'>Bucket Seats</label>
                                        </li>
                                        <li>
                                            <input onChange={handleChangeCheck} checked={userinfo.seats.includes("memory seats")} className='form-check-input' type="checkbox" name="seats" value="memory seats" id="" />
                                            <label className='form-check-label'>Memory Seats</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="meanufacturer">
                                <div className="h6">Manufacturer</div>
                                <span className='mr-3 dib'>
                                    <input className='mr-2' onChange={(e)=>handleData(e)} checked={formDataVal?.manufacturer == 'local'} type="radio" name="manufacturer" value={"local"} id="" />
                                    <label>Local</label>
                                </span>
                                <span>
                                    <input className='mr-2' onChange={(e)=>handleData(e)} checked={formDataVal?.manufacturer == 'imported'} type="radio" name="manufacturer" value={"imported"} id="" />
                                    <label>Imported</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="setFeatured mb-2">
                            <input  onChange={(e)=>setIsFeatured(e.target.checked)} checked={isFeatured} className='form-check-input' type="checkbox" id="" />
                            <label className='form-check-label'>Set As Featured</label>
                        </div>
                        <div className="setFeatured mb-3">
                            <input  onChange={(e)=>setIsWaterMark(e.target.checked)} checked={isWaterMark} className='form-check-input' type="checkbox" id="" />
                            <label className='form-check-label'>Add Images With Water Mark</label>
                        </div>
                        <div className="imagesMain">
                            <h4 className='mb-3'>Upload Images</h4>
                            <div className="imgInput">
                                <input multiple onChange={(e)=>handleImages(e)} type="file" accept='image/*' />
                            </div>
                            <div className="note">
                                Note:
                                <span>
                                    Each image must be less than 300kb
                                </span>
                            </div>
                            <div className="imgCardM" >
                                {upImages.map((item,key)=>(
                                    <div className="imgCard" key={key}>
                                        <div className="deleteImg" onClick={()=>removeImg(key)}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </div>
                                        <img src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/${item}`} alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <h4 className='mb-3'>Description</h4>
                        <div className="descMain">
                            <ReactQuill placeholder='Add description' modules={Editor} theme="snow" defaultValue={formDataVal?.description} value={formDataVal?.description} onChange={handleChangeDesc} />
                        </div>
                        <div className='mt-4'>
                            <button className="themeBtn" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
        {/* table */}

     </div>
      }
     
   </div>
    {loader &&
    <LoaderComp /> 
    }
 </div>
  )
}
